import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {
    MDBIcon,
    MDBContainer,
    MDBNavbar,
    MDBInput,
    MDBInputGroup,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem as MDBDropdownLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBTypography,
    MDBBtn,
    MDBProgress,
    MDBProgressBar,
    MDBDatatable,
    MDBListGroupItem,
    MDBSpinner,
    MDBModal,
    MDBModalContent,
    MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter,
    MDBModalDialog, MDBDropdownItem
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import axios from "axios";
import Moment from "react-moment";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {Typography} from "@mui/material";
import {saveAs} from "file-saver";
import {NotificationManager} from "react-notifications";
import eventBus from "../../../../Context/EventBus";
import ErrorBoundary from "../../Components/ErrorBoundary";



const GET_ROWS = gql`
          query Get($entityId: Long!, $fileId: String!, $skip: Int!, $take: Int!) {
          
          
              fileDataRows(entityId: $entityId, fileId: $fileId, skip: $skip, take: $take) {
                items{
                  file_id
                  row_id
                  data
                }
            
              pageInfo {
                hasNextPage
                hasPreviousPage
                
              }
              totalCount
            
              
              }
            }
        `;


const GET_FILE = gql`
          query Get($entityId: Long!, $fileId: String!) {
          
              file(entityId: $entityId, fileId: $fileId)
                {
                  id
                  name
                  size
                  uploaded_ts
                  uploaded_by
                  row_count
                  time_to_process_seconds
                  approved
                  status
                  delimiter
                  extension
                }
          
             
              
            }
        `;


const DELETE_FILE = gql`
mutation delete($entityId: Long!, $fileId: String!)
{
    deleteFile( entityId: $entityId, fileId: $fileId) {
        successful
    }
}
`;


const REQUEUE_FILE = gql`
mutation delete($entityId: Long!, $fileId: String!)
{
    reprocessFile( entityId: $entityId, fileId: $fileId) {
        successful
    }
}
`;

const allowedExtensions = ['.csv'];

const isProcessable = (file) => {
    const fileExtension = file.extension || file.name.split('.').pop();
    console.log(file);
    console.log(fileExtension);
    return allowedExtensions.includes(`${fileExtension}`);
};


const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params= useParams()
    const fetchSize = 25;
    const [pagedata, setPagedata] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const [loadedRows, setLoadedRows] = useState(0);

    const [columns, setColumns] = useState([]);

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getFileData,{error, loading: drugLoading, data: drugData}] = useLazyQuery(GET_ROWS);


    const { data, fetchNextPage, isError, isFetching, isLoading } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {


                let dd = await getFileData({
                    variables: {
                        entityId: Number(params.entity_id),
                        fileId: params.file_id,
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                    },
                    context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
                    .then();


                try {

                    let tColumns = [].concat(
                        Object.keys(JSON.parse(dd?.data?.fileDataRows?.items[0].data))
                            .filter(row => (row !== undefined && row !== null && row.length>0))
                            .map( (row) => {
                                return {accessorKey:row, header: row}
                            }));

                    setColumns(tColumns)

                    setTotalRows(dd?.data?.fileDataRows?.totalCount ?? 0)


                    return dd?.data?.fileDataRows?.items.map( (row) => {
                        return JSON.parse(row.data)
                    });

                }
                catch  {
                    return [];
                }

                // let tcolumns = dd.data.fileDataRows.items.map( (row) => {
                //    return Object.keys(JSON.parse(row.data));
                // })

                //setColumns(Array.from(new Set(columns.concat(tcolumns).flatMap((row) =>row))).map( (row) => { return {accessorKey:row, header: row}}));

            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,

        });


    const flatData = useMemo(
        () => data?.pages?.flatMap((page) => page) ?? [],
        [data],
    );

    const totalDBRowCount = totalRows ?? 0;

    const totalFetched = flatData.length;

    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        async (containerRefElement) => {
            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;

                // console.log('fetchMoreOnBottomReached: height', scrollHeight - scrollTop - clientHeight)
                // console.log('fetchMoreOnBottomReached: isFetching', isFetching)
                // console.log('fetchMoreOnBottomReached: totalFetched', totalFetched)
                // console.log('fetchMoreOnBottomReached: totalDBRowCount', totalDBRowCount)

                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ){

                    await fetchNextPage().then();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current).then();
    }, [fetchMoreOnBottomReached]);


    return (
        <div >

            <MaterialReactTable
                columns={columns}
                data={flatData}

                enableColumnFilters={false}
                enablePagination={false}
                enableGlobalFilter={true}
                enableFilters={true}
                enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }


                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}

                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {totalDBRowCount.toLocaleString("en-US")} total rows.
                    </Typography>
                )}


                enableDensityToggle={false}
                initialState={{ density: 'compact' }}


                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                rowVirtualizerProps={{ overscan: 4 }}
            />
        </div>
    );
};


const queryClient = new QueryClient();
function getBaseURL()
{
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
        return "https://pre-data.clearwayhealth.com/api/";
    else
        return "/api/";
}


export function Page({user}) {
    const params= useParams()
    const [uploadPercent, setUploadPercent] = useState(0);
    const [dbDeleteFile] = useMutation(DELETE_FILE);
    let navigate = useNavigate();

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showReprocessConfirmation, setReprocessConfirmation] = useState(false);


    const [ReProcessFile] = useMutation(REQUEUE_FILE,{
        variables: {
            entityId: Number(params.entity_id),
            fileId: params.file_id,
        },
        context:{
            headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
        }
    })


    function Reprocess()
    {
        ReProcessFile().then( () => {
            NotificationManager.success("File has been queued for reprocessing!");
        })
    }


    function DeleteFile()
    {
        dbDeleteFile({
            variables: {
                entityId: Number(params.entity_id),
                fileId: params.file_id,
            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {
                NotificationManager.success("Deleted File ");
                fileRefetch().then();

                navigate("/admin/entity/"+ params.entity_id + "/file")

                //what do we do now?
            })
    }



    const { loading: fileLoading, error: fileError, data: fileData, refetch: fileRefetch } = useQuery(GET_FILE, {
        variables:{
            entityId: Number(params.entity_id),
            fileId: params.file_id,
        },
        context:{
            headers:{
                "Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'
            }
        }
    });


    function downloadFile()
    {
        setUploadPercent(1);
        axios.get(getBaseURL() + "file/v1/download/"+params.entity_id+"/" + params.file_id, {
            onDownloadProgress: (progressEvent) => {const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50; setUploadPercent(progress); },
            responseType: "blob",
            headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : 'none'}
        }).then( (result) => {
            saveAs(result.data,  params.file_id + ".csv");
            setUploadPercent(100);
            setTimeout( () => {setUploadPercent(0);}, 1000)
        });

    }


    if(fileLoading) return (<div className="text-center" style={{paddingTop:"50px"}}><MDBSpinner animation="grow"  variant="primary" /></div>);

    return (

        <MDBContainer >
            {uploadPercent>0 &&
            <MDBProgress className="m-2">
                <MDBProgressBar width={uploadPercent} valuemin={0} valuemax={100} />
            </MDBProgress>
            }

            <MDBBreadcrumb >
                <MDBBreadcrumbItem>
                    <Link to={"/admin/home"}>Admin</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity"}>Entity</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id}>{params.entity_id}</Link>
                </MDBBreadcrumbItem>

                <MDBBreadcrumbItem>
                    <Link to={"/admin/entity/" + params.entity_id + "/file"}>Files</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>{params.file_id}</MDBBreadcrumbItem>
            </MDBBreadcrumb>

            <div className="position-relative position-relative-example">


                <MDBRow >
                    <MDBCol className="text-center">
                        <MDBTypography tag='h2'> </MDBTypography>

                    </MDBCol>


                </MDBRow>

                <div className="position-absolute top-0 end-0 ">

                    <MDBDropdown>
                        <MDBDropdownToggle>Actions</MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem link onClick={downloadFile} style={{cursor:"pointer"}}>Download</MDBDropdownItem>
                            <MDBDropdownItem link onClick={()=>setShowDeleteConfirmation(true)} style={{cursor:"pointer"}} >Delete</MDBDropdownItem>
                            <MDBDropdownItem link onClick={()=>setReprocessConfirmation(true)} style={{cursor:"pointer"}}>  {fileData.file.status === "not processed" ? "Process" : "Reprocess"} </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>

                    {/*<MDBBtn onClick={downloadFile}>Download</MDBBtn>*/}
                </div>


            </div>


            <MDBRow className="pt-4">
                <MDBCol md={6}>
                    <MDBTable>

                        <MDBTableBody>
                            <tr>
                                <th scope='row' className="fw-bold">File ID</th>
                                <td>{fileData?.file.id}</td>
                            </tr>
                            <tr>
                                <th scope='row'  className="fw-bold">File Name</th>
                                <td>{fileData?.file.name}</td>
                            </tr>
                            <tr>
                                <th scope='row'  className="fw-bold">Uploaded By</th>
                                <td>{fileData?.file.uploaded_by}</td>
                            </tr>
                            <tr>
                                <th scope='row'  className="fw-bold">Uploaded Date Time</th>
                                <td><Moment utc local format={"MM/DD/YYYY HH:mm:ss"}>{fileData?.file.uploaded_ts}</Moment></td>
                            </tr>
                            <tr>
                                <th scope='row'  className="fw-bold">Delimiter</th>
                                <td>{fileData?.file.delimiter}</td>
                            </tr>
                        </MDBTableBody>


                    </MDBTable>
                </MDBCol>
                <MDBCol md={6}>
                    <MDBTable>

                        <MDBTableBody>
                            <tr>
                                <th scope='row'  className="fw-bold">Size</th>
                                <td>{((fileData?.file.size / (1024)).toFixed(0))} KB</td>
                            </tr>
                            <tr>
                                <th scope='row'  className="fw-bold">Row Count</th>
                                <td>{fileData?.file.row_count}</td>
                            </tr>
                            <tr>
                                <th scope='row'   className="fw-bold">Processing Stage</th>
                                <td style={{textTransform: 'capitalize'}}>{fileData?.file.status}</td>
                            </tr>
                            <tr>
                                <th scope='row'  className="fw-bold">Extension</th>
                                <td>{fileData?.file.extension} <span> </span>
                                        {
                                        isProcessable(fileData?.file) ? (<span className="processable" title="File Processable!">✔</span>) : 
                                        (<span className="not-processable" title="File Not Processable">✘</span>)
                                        }
                                </td>
                            </tr>
                            <tr>
                                <th scope='row'  className="fw-bold">Approved</th>
                                <td>{fileData?.file.approved ? "Yes" : "No"}</td>
                            </tr>

                        </MDBTableBody>


                    </MDBTable>
                </MDBCol>
            </MDBRow>


            {fileData?.file.status === "complete" && fileData?.file.row_count > 0 &&
            <MDBRow>
                <MDBCol>
                    <QueryClientProvider client={queryClient}>
                        <ErrorBoundary fallback={<><center>Something went wrong, we're unable to load the data.</center></>}>
                        <DrugTable user={user} />
                        </ErrorBoundary>
                    </QueryClientProvider>
                </MDBCol>
            </MDBRow>
            }


            <MDBModal tabIndex='-1' open={showDeleteConfirmation} onClose={()=>setShowDeleteConfirmation(false)}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Delete Confirmation</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={()=> setShowDeleteConfirmation(false)}/>
                        </MDBModalHeader>
                        <MDBModalBody >
                            Are you sure you want to delete this file?
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color={"danger"} onClick={() => {
                                DeleteFile();
                                setShowDeleteConfirmation(false)
                            }}>Delete</MDBBtn>
                            <MDBBtn color='primary' onClick={()=>{setShowDeleteConfirmation(false)}}>Cancel</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            <MDBModal tabIndex='-1' open={showReprocessConfirmation} onClose={()=>setReprocessConfirmation(false)}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Reprocess Confirmation</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={()=> setReprocessConfirmation(false)}/>
                        </MDBModalHeader>
                        <MDBModalBody >
                            Are you sure you want to reprocess this file?
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color={"danger"} onClick={() => {
                                Reprocess();
                                setReprocessConfirmation(false)
                            }}>Reprocess</MDBBtn>
                            <MDBBtn color='primary' onClick={()=>{setReprocessConfirmation(false)}}>Cancel</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </MDBContainer>
    );
}


const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Page)
