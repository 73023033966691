import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavCollapse,
    MDBIcon, MDBBtn,
} from 'mdb-react-ui-kit';
import {
    BrowserRouter as Router,
    Routes,
    Route, Link
} from "react-router-dom";

import {HomePage} from './Pages/Home';
import {ClientLoginOTPPage, ClientLoginPage} from './Pages/Login';


import {Header} from "./Components/Header";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {LogoutPage} from "./Pages/Logout";
import {AdminSection} from "./Pages/Admin";
import {default as ClientPages} from "./Pages/Clients";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {resetUser, setUser} from "./Context/reducers/User/actions";
import {connect} from "react-redux";

// Notice(JD): This import may need to be fixed later in a style refactor
import './Styles/fileData.css';



 function Page({user}) {
    const contentRef = useRef(null);
    const [isSlim, setIsSlim] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
     const [collapseHelp, setCollapseHelp] = useState(false);



     useEffect(() => {
         setInterval(() => {

             if(user.isAuthenticated && user.isAdmin)
             {
                 setIsAdmin(true)
             }
             else
             {
                 setIsAdmin(false)
             }

         }, 100);
     }, []);



    return(
        <Router>

            <MDBSideNav backdrop={false} mode={'push'}   open={isAdmin} slim={isSlim}     style={{marginTop:'8rem', height: "410px"}} closeOnEsc={false}>
                <div className='mt-3 mb-3'>
                    <div id='header-content' className=' text-center'>

                        <MDBBtn className='sidenav-slim btn-sm' onClick={() => setIsSlim(!isSlim)}>
                            <MDBIcon fas icon="angle-double-right" />
                        </MDBBtn>
                        <MDBBtn className='sidenav-non-slim btn-sm' onClick={() => setIsSlim(!isSlim)}>
                            <MDBIcon fas icon="angle-double-left" />
                        </MDBBtn>

                    </div>

                </div>


                <MDBSideNavMenu>
                    <div className='mt-2 sidenav-non-slim ' style={{transition: 'padding 0.3s linear'}}> </div>
                    <MDBSideNavItem>
                        <Link to={"/admin/home"}  >
                            <MDBSideNavLink style={{color:"black"}} onClick={() =>setIsSlim(true)}>
                                <MDBIcon fas icon="home"   color={"dark"} className='fas fa-fw me-3' />
                                <span className='sidenav-non-slim'>Admin Home</span>
                            </MDBSideNavLink>
                        </Link>
                    </MDBSideNavItem>
                    <hr className='mb-0 mt-0' />


                    <MDBSideNavItem>

                        <Link to={"/admin/entity"}>
                            <MDBSideNavLink style={{color:"black"}} onClick={() =>setIsSlim(true)}>
                                <MDBIcon icon='clinic-medical'  color={"dark"} className='fas fa-fw me-3' />
                                <span className='sidenav-non-slim'>Entities</span>
                            </MDBSideNavLink>
                        </Link>


                    </MDBSideNavItem>
                    <hr className='mb-0 mt-0' />


                    <MDBSideNavItem>

                        <Link to={"/admin/drug"}>
                            <MDBSideNavLink style={{color:"black"}} onClick={() =>setIsSlim(true)}>
                                <MDBIcon icon='capsules'  color={"dark"} className='fas fa-fw me-3' />

                                <span className='sidenav-non-slim'>Drugs</span>
                            </MDBSideNavLink>
                        </Link>


                    </MDBSideNavItem>
                    <hr className='mb-0 mt-0' />
                    <MDBSideNavItem>

                        <Link to={"/admin/pharmacy"}>
                            <MDBSideNavLink style={{color:"black"}} onClick={() =>setIsSlim(true)}>
                                <MDBIcon icon='prescription-bottle-alt' color={"dark"} className='fas fa-fw me-3' />
                                <span className='sidenav-non-slim'>Pharmacies</span>
                            </MDBSideNavLink>
                        </Link>


                    </MDBSideNavItem>
                    <hr className='mb-0 mt-0' />


                    <MDBSideNavItem>

                        <Link to={"/admin/provider"}>
                            <MDBSideNavLink style={{color:"black"}} onClick={() =>setIsSlim(true)}>
                                <MDBIcon icon='clinic-medical'  color={"dark"} className='fas fa-fw me-3' />
                                <span className='sidenav-non-slim'>Providers</span>
                            </MDBSideNavLink>
                        </Link>


                    </MDBSideNavItem>
                    <hr className='mb-0 mt-0' />


                    <MDBSideNavItem>
                        <Link to={"/admin/user"}>
                            <MDBSideNavLink style={{color:"black"}} onClick={() =>setIsSlim(true)}>
                                <MDBIcon icon='user-alt'  color={"dark"} className='fas fa-fw me-3' />
                                <span className='sidenav-non-slim'>Users</span>
                            </MDBSideNavLink>
                        </Link>
                    </MDBSideNavItem>
                    <hr className='mb-0 mt-0' />



                    <MDBSideNavItem>
                        <Link to={"/admin/help"}>
                            <MDBSideNavLink style={{color:"black"}} onClick={() =>setIsSlim(true)}>
                                <MDBIcon icon='question'  color={"dark"} className='fas fa-fw me-3' />
                                <span className='sidenav-non-slim'>Help</span>
                            </MDBSideNavLink>
                        </Link>
                    </MDBSideNavItem>


                </MDBSideNavMenu>



            </MDBSideNav>

            <div ref={contentRef}>
                <Header  />
                <Routes >
                    <Route path="/"  element={<HomePage />} />
                    <Route path="/login/:email"  element={<ClientLoginOTPPage />} />
                    <Route path="/login"  element={<ClientLoginPage />} />
                    <Route path="/logout"  element={<LogoutPage />} />
                    <Route path="/client/*"  element={<ClientPages />} />
                    <Route path="/admin/*" element={<AdminSection />} />
                </Routes>
                <NotificationContainer/>
            </div>
        </Router>
    )



  //   // return (
  //   // <Router>
  //   //     <div  style={{display:'flex', minHeight:'100vh', flexDirection:'column', justifyContent:'flex-start', fontFamily:'Geneva'}}>
  //   //     <Header />
  //   //       <Routes>
  //   //         <Route path="/"  element={<HomePage />} />
  //   //           <Route path="/login/:email"  element={<ClientLoginOTPPage />} />
  //   //
  //   //           <Route path="/login"  element={<ClientLoginPage />} />
  //   //         <Route path="/logout"  element={<LogoutPage />} />
  //   //           <Route path="/client/*"  element={<ClientPages />} />
  //   //         <Route path="/admin/*" element={<AdminSection />} />
  //   //
  //   //       </Routes>
  //   //     <Footer />
  //   //         <NotificationContainer/>
  //   //     </div>
  //   // </Router>
  // );
}



const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser, resetUser}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Page)


